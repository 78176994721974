// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@mixin anim_flash($params) {
  -webkit-animation: $params;
  -moz-animation: $params;
  animation: $params;

  @-webkit-keyframes flash {
    0%{opacity: 1;}
    100%{opacity:0.3;}
  }
  @-moz-keyframes flash {
    0%{opacity: 1;}
    100%{opacity:0.3;}
  }
  @keyframes flash {
    0%{opacity: 1;}
    100%{opacity:0.3;}
  }
}

@mixin anim_appear($params) {
  -webkit-animation: $params;
  -moz-animation: $params;
  animation: $params;

  @-webkit-keyframes appear {
    0%{-webkit-transform: scale(0)}
    100%{-webkit-transform: scale(1)}
  }
  @-moz-keyframes appear {
    0%{-moz-transform: scale(0)}
    100%{-moz-transform: scale(1)}
  }
  @keyframes appear {
    0%{transform: scale(0)}
    100%{transform: scale(1)}
  }
}

@mixin anim_bounce($params) {
  -webkit-animation: $params;
  -moz-animation: $params;
  animation: $params;

  @-webkit-keyframes bounce {
    0%{-webkit-transform: scale(0)}
    75%{-webkit-transform: scale(1)}
    80%{-webkit-transform: scale(1.1)}
    100%{-webkit-transform: scale(1)}
  }
  @-moz-keyframes bounce {
    0%{-moz-transform: scale(0)}
    75%{-moz-transform: scale(1)}
    80%{-moz-transform: scale(1.1)}
    100%{-moz-transform: scale(1)}
  }
  @keyframes bounce {
    0%{transform: scale(0)}
    75%{transform: scale(1)}
    80%{transform: scale(1.1)}
    85%{transform: scale(1)}
    90%{transform: scale(1.1)}
    100%{transform: scale(1)}
  }
}

@mixin anim_delay($params) {
  -webkit-animation-delay: $params;
  -moz-animation-delay: $params;
  animation-delay: $params;
}

.loadingModal--block {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: table;
    margin: auto;
    width: 380px;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(49,49,49,0.6);
    @include anim_appear(appear 0.3s ease-in-out);
    p {
      font-size: 24px;
      font-weight: 300;
      color: #080808;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      letter-spacing: 6px;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      @for $i from 0 through 7 {
        span:nth-child(#{$i + 1}) {
          @include anim_flash(flash 1.2s ease-in-out infinite);
          @include anim_delay(#{0.1 * $i}s);
        }
      }
    }
  }
}

.alertModal--block {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .content {
    &:before {
      content: '!';
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      border: solid 3px #DCDCDC;
      background-color: #d92a25;
      color: #fff;
      font-size: 40px;
      text-align: center;
      box-shadow: 1px 1px 0 #777;
      @include anim_bounce(bounce 1s ease-in-out);
    }
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    width: 380px;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(49,49,49,0.6);
    @include anim_appear(appear 0.3s ease-in-out);
    p {
      font-size: 14px;
      font-weight: 100;
      color: #080808;
      text-align: center;
      display: block;
      width: 100%;
      position: absolute;
      top: 60px;
    }
    button {
      background-color: inherit;
      border: 1px solid #CCC;
      border-radius: 5px;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 10px;
      display: block;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}

.linkModal--block {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    width: 380px;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(49,49,49,0.6);
    @include anim_appear(appear 0.3s ease-in-out);
    a {
      font-size: 14px;
      font-weight: 300;
      color: #080808;
      text-align: center;
      display: block;
      width: 100%;
      position: absolute;
      top: 60px;
      &:active, &:visited{
        color: #080808;
        text-decoration: none;
      }
    }
    button {
      background-color: inherit;
      border: 1px solid #CCC;
      border-radius: 5px;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 10px;
      display: block;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}

.buttonModal--block {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    width: 380px;
    height: 160px;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(49,49,49,0.6);
    @include anim_appear(appear 0.3s ease-in-out);

    button {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 300;
      color: #080808;
      text-align: center;

      &:nth-last-child(1) {
        background-color: inherit;
        border: 1px solid #CCC;
        border-radius: 5px;
        width: 100px;
        height: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
        opacity: 1;
        float: none;
      }
    }

  }
}